"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent_1 = require("./BaseCustomComponent");
var ColorCustomComponent = /** @class */ (function (_super) {
    __extends(ColorCustomComponent, _super);
    // #endregion Properties (3)
    // #region Constructors (1)
    function ColorCustomComponent(id, parameterDefinition, parent, domManager, queue, _useSpectrum, _tinyColorUtil, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, "color" /* COLOR */, id, parameterDefinition, parent, domManager, queue, editMode) || this;
        _this._useSpectrum = _useSpectrum;
        _this._tinyColorUtil = _tinyColorUtil;
        _this._inputId = id + "-color";
        _this._initalValue = _tinyColorUtil.toTinyColor(_this._parameterDefinition.value);
        _this.createElement();
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(ColorCustomComponent.prototype, "colorPicker", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return this._colorPicker;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorCustomComponent.prototype, "size", {
        get: function () {
            return 1 /* QTR_WIDTH */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ColorCustomComponent.prototype, "value", {
        get: function () {
            return this._inputComponent.value;
        },
        set: function (v) {
            var color = v;
            if (typeof v !== "object") {
                color = this._tinyColorUtil.toTinyColor(v);
            }
            // keep track of old value, in order to avoid firiting up events.
            this._inputComponent.value = this._useSpectrum ? color.toRgbString() : color.toHexString();
            if (this._useSpectrum) {
                if (this._previousValue !== this._inputComponent.value) {
                    this._previousValue = this._inputComponent.value;
                    var event_1 = document.createEvent("Event");
                    event_1.initEvent("change", false, true);
                    this._inputComponent.dispatchEvent(event_1);
                    $("#" + this._inputId).spectrum("set", this.value);
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    ColorCustomComponent.prototype.setToInitalValueAndClearStack = function () {
        _super.prototype.setToInitalValueAndClearStack.call(this);
        if (this._useSpectrum) {
            $("#" + this._inputId).spectrum("set", this.value);
        }
    };
    // #endregion Public Accessors (2)
    // #region Protected Methods (3)
    ColorCustomComponent.prototype.createInput = function () {
        this._useSpectrum ? this.createSpectrumControls() : this.createNonSpectrumControls();
    };
    ColorCustomComponent.prototype.createInputEvents = function () {
        this._domManager.setDomEventListener(this._inputComponent, "change", 10, this.update.bind(this));
    };
    // #endregion Protected Methods (3)
    // #region Private Methods (2)
    ColorCustomComponent.prototype.isCurrentValueSameAsInital = function () {
        if (this._useSpectrum)
            return this._tinyColorUtil.toTinyColor(this.value).toRgbString() === this._tinyColorUtil.toTinyColor(this._initalValue).toRgbString();
        return this.value === this._initalValue;
    };
    ColorCustomComponent.prototype.createNonSpectrumControls = function () {
        this._inputComponent = this._domManager.createDomElement("input", this._inputId, this._inputContainer, ["uk-input", "uk-form-small", "uk-form-width-xsmall"], { name: this._inputId });
        this._inputComponent.setAttribute("type", "color");
        this._inputComponent.value = this._initalValue.toHexString();
        this._colorPicker = this._inputComponent;
    };
    ColorCustomComponent.prototype.createSpectrumControls = function () {
        var _this = this;
        this._inputComponent = this._domManager.createDomElement("input", this._inputId, this._inputContainer, ["uk-hidden"], { type: 'text' });
        $("#" + this._inputId).spectrum({
            color: this._initalValue.toRgbString(),
            appendTo: document.getElementById("sdv-container"),
            replacerClassName: "uk-padding-remove",
            showInput: true,
            showInitial: true,
            preferredFormat: "rgb",
            change: function (color) { return _this.value = color; }
        });
        this._colorPicker = this.row.getElementsByClassName("sp-replacer")[0];
    };
    return ColorCustomComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.ColorCustomComponent = ColorCustomComponent;
