"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var BaseParameterComponent_1 = require("./BaseParameterComponent");
var DropdownCustomComponent_1 = require("../custom-components/DropdownCustomComponent");
var DropdownParameterComponent = /** @class */ (function (_super) {
    __extends(DropdownParameterComponent, _super);
    function DropdownParameterComponent(api, domManager, queue, container, parameter, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, api, domManager, queue, new DropdownCustomComponent_1.DropdownCustomComponent(parameter.id, {
            name: parameter.name,
            type: parameter.elementType,
            title: parameter.note,
            value: parameter.choices[Number(parameter.defval)],
            choices: parameter.choices,
            order: parameter.order,
        }, container, domManager, queue), parameter, editMode) || this;
        _this._component.parameterDefinition.update = _this.updateAsync.bind(_this);
        return _this;
    }
    DropdownParameterComponent.prototype.updateAsync = function (value) {
        return __awaiter(this, void 0, void 0, function () {
            var index, res;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        index = this._component.getChoiceIndexFromValue(value);
                        if (!!this._queue.executing) return [3 /*break*/, 2];
                        return [4 /*yield*/, this._api.parameters.updateAsync({ id: this._parameter.id, value: index })];
                    case 1:
                        res = _a.sent();
                        this.value = value;
                        return [2 /*return*/, Promise.resolve(res)];
                    case 2:
                        BaseParameterComponent_1.BaseParameterComponent._toExecuteOnQueuePassFinished.push({ id: this._parameter.id, value: index });
                        this.value = value;
                        return [2 /*return*/, Promise.resolve(null)];
                }
            });
        });
    };
    DropdownParameterComponent.prototype.handleValueUpdateEvent = function (event) {
        if (event.parameter) {
            var pluginKeys = Object.keys(event.parameter);
            for (var i = 0; i < pluginKeys.length; i++) {
                var pluginKey = pluginKeys[i];
                var parametersKeys = Object.keys(event.parameter[pluginKey]);
                for (var j = 0; j < parametersKeys.length; j++) {
                    var parameterKey = parametersKeys[j];
                    if (parameterKey === this.id) {
                        var val = event.parameter[pluginKey][parameterKey];
                        this.value = this._parameter.choices[val];
                        break;
                    }
                }
            }
        }
    };
    return DropdownParameterComponent;
}(BaseParameterComponent_1.BaseParameterComponent));
exports.DropdownParameterComponent = DropdownParameterComponent;
