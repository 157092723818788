"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseParameterComponent_1 = require("./BaseParameterComponent");
var ChecklistCustomComponent_1 = require("../custom-components/ChecklistCustomComponent");
var ChecklistParameterComponent = /** @class */ (function (_super) {
    __extends(ChecklistParameterComponent, _super);
    // #region Constructors (1)
    function ChecklistParameterComponent(api, domManager, queue, container, parameter, editMode) {
        if (editMode === void 0) { editMode = false; }
        var _this = _super.call(this, api, domManager, queue, new ChecklistCustomComponent_1.ChecklistCustomComponent(parameter.id, {
            name: parameter.name,
            type: parameter.elementType,
            title: parameter.note,
            value: parameter.defval ? (Array.isArray(parameter.defval) ? parameter.defval.map(function (i) { return parameter.choices[i]; }) : parameter.defval.split(',').map(function (x) { return parameter.choices[x]; })) : [],
            choices: parameter.choices,
            order: parameter.order,
        }, container, domManager, queue), parameter, editMode) || this;
        _this._component.parameterDefinition.update = _this.update.bind(_this);
        return _this;
    }
    Object.defineProperty(ChecklistParameterComponent.prototype, "value", {
        // #endregion Constructors (1)
        // #region Public Accessors (2)
        get: function () {
            return this._component.value;
        },
        set: function (v) {
            var _this = this;
            this._component.value = v.map(function (x) { return _this._parameter.choices[x]; });
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (2)
    // #region Protected Methods (1)
    ChecklistParameterComponent.prototype.update = function (val) {
        var _this = this;
        var indexVal = val.map(function (v) { return _this._parameter.choices.indexOf(v); });
        this._api.parameters.updateAsync({ id: this._parameter.id, value: indexVal });
    };
    return ChecklistParameterComponent;
}(BaseParameterComponent_1.BaseParameterComponent));
exports.ChecklistParameterComponent = ChecklistParameterComponent;
