"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/** @ignore */
var THREE = require("../../externals/three");
var GLOBAL_UTILS = __importStar(require("../../shared/util/GlobalUtils"));
var SnapUtils = /** @class */ (function () {
    function SnapUtils() {
    }
    SnapUtils.prototype.eval = function (e, snap, _startPosition, _currentObjPos, _anchors, _eventListeners, _eventTypes, first) {
        var e_1, _a, e_2, _b;
        var ids = snap.snapElement.anchorIds;
        // multiple anchor defined
        if (ids.length === 0)
            ids = Object.keys(_anchors);
        // step 3: select anchor from snapElement with projected distance to actual position
        var anchors = [];
        var currentPos = new THREE.Vector3(e.worldPos.x, e.worldPos.y, e.worldPos.z).sub(_startPosition).add(_currentObjPos);
        var snappedBefore = e.obj.snap;
        try {
            for (var _c = __values(first ? [ids[0]] : ids), _d = _c.next(); !_d.done; _d = _c.next()) {
                var anchorId = _d.value;
                var anchor = _anchors[anchorId];
                var matrix_1 = anchor.rotationMatrix.clone().multiply(snap.snapElement.rotationMatrix.clone());
                // move to center (with rotation in mind)
                var anchorToCenter = new THREE.Vector3().sub(anchor.position.clone().sub(_currentObjPos)).applyMatrix4(matrix_1);
                // add the snap position
                var p = anchorToCenter.clone().add(snap.position);
                var otherAnchorPositions = [];
                try {
                    for (var ids_1 = (e_2 = void 0, __values(ids)), ids_1_1 = ids_1.next(); !ids_1_1.done; ids_1_1 = ids_1.next()) {
                        var anchorId2 = ids_1_1.value;
                        var anchor2 = _anchors[anchorId2];
                        var matrix_2 = anchor2.rotationMatrix.clone().multiply(snap.snapElement.rotationMatrix.clone());
                        var anchorToCenter2 = new THREE.Vector3().sub(anchor2.position.clone().sub(_currentObjPos)).applyMatrix4(matrix_2);
                        otherAnchorPositions.push({
                            anchorId: anchor2.id,
                            position: anchorToCenter2.clone().add(snap.position)
                        });
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (ids_1_1 && !ids_1_1.done && (_b = ids_1.return)) _b.call(ids_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
                anchors.push({
                    dragEvent: e,
                    snap: true,
                    snappedBefore: snappedBefore,
                    distance: p.distanceTo(currentPos),
                    anchorId: anchor.id,
                    position: p.clone(),
                    nonRotatedPosition: _startPosition.clone().sub(anchor.position).applyMatrix4(matrix_1).add(snap.position),
                    anchorPositions: otherAnchorPositions,
                    rotationMatrix: snap.snapElement.rotationMatrix.clone()
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_d && !_d.done && (_a = _c.return)) _a.call(_c);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.sortDistance(anchors);
        e.obj.snap = true;
        var newPosition = new THREE.Vector3(anchors[0].position.x, anchors[0].position.y, anchors[0].position.z);
        var diff = newPosition.clone().sub(_currentObjPos);
        var diffNonRotated = anchors[0].nonRotatedPosition.clone().sub(_currentObjPos);
        e.obj.position.copy(newPosition);
        e.obj.nonRotatedPosition = anchors[0].nonRotatedPosition.clone();
        var matrix = _anchors[anchors[0].anchorId].rotationMatrix.clone().multiply(snap.snapElement.rotationMatrix);
        e.obj.setRotationFromMatrix(matrix);
        for (var i = 0; i < e.obj.additionalDragObjects.length; i++) {
            e.obj.additionalDragObjects[i].snap = true;
            e.obj.additionalDragObjects[i].position.copy(e.obj.additionalDragObjects[i].additionalDragOriginalPosition.clone().add(diff));
            e.obj.additionalDragObjects[i].nonRotatedPosition = e.obj.additionalDragObjects[i].additionalDragOriginalPosition.clone().add(diffNonRotated);
            e.obj.additionalDragObjects[i].setRotationFromMatrix(matrix);
        }
        // forward event with new data
        for (var id in _eventListeners[_eventTypes.DRAG_MOVE])
            _eventListeners[_eventTypes.DRAG_MOVE][id](anchors[0]);
        return {
            dragEvent: null,
            snap: anchors[0].snap,
            snappedBefore: snappedBefore,
            distance: anchors[0].distance,
            anchorId: anchors[0].anchorId,
            position: anchors[0].position,
            nonRotatedPosition: anchors[0].nonRotatedPosition,
            anchorPositions: anchors[0].anchorPositions,
            rotationMatrix: anchors[0].rotationMatrix,
        };
    };
    SnapUtils.prototype.sortDistance = function (array) {
        array.sort(function (a, b) {
            return a.distance - b.distance;
        });
    };
    SnapUtils.prototype.sortDot = function (array) {
        array.sort(function (a, b) {
            return b.dot - a.dot;
        });
    };
    SnapUtils.prototype.snapElements = function (e, startPosition, elements, snaps, anchors) {
        var e_3, _a;
        for (var snapId in elements) {
            var element = elements[snapId];
            var ids = element.anchorIds;
            // multiple anchor defined
            if (ids.length === 0)
                ids = Object.keys(anchors);
            try {
                for (var ids_2 = (e_3 = void 0, __values(ids)), ids_2_1 = ids_2.next(); !ids_2_1.done; ids_2_1 = ids_2.next()) {
                    var anchorId = ids_2_1.value;
                    var anchor = anchors[anchorId];
                    var currentPos = new THREE.Vector3(e.worldPos.x, e.worldPos.y, e.worldPos.z).sub(startPosition).add(anchor.position);
                    snaps.push(element.snap(currentPos));
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (ids_2_1 && !ids_2_1.done && (_a = ids_2.return)) _a.call(ids_2);
                }
                finally { if (e_3) throw e_3.error; }
            }
        }
    };
    SnapUtils.prototype.createRandomId = function () {
        return Math.random().toString(36).substring(7);
    };
    SnapUtils.prototype.cleanPointInput = function (def) {
        var out = {};
        if (!def)
            return out;
        out.anchorElements = GLOBAL_UTILS.isArrayOfType(def.anchorElements, 'string') ? def.anchorElements : [];
        out.id = GLOBAL_UTILS.typeCheck(def.id, 'string') ? def.id : this.createRandomId();
        out.orientation = this.cleanOrientation(def.orientation);
        out.position = this.cleanPosition(def.position);
        out.snapRadius = GLOBAL_UTILS.typeCheck(def.snapRadius, 'notnegative') ? def.snapRadius : 1;
        return out;
    };
    SnapUtils.prototype.cleanLineInput = function (def) {
        var out = {};
        if (!def)
            return out;
        out.anchorElements = GLOBAL_UTILS.isArrayOfType(def.anchorElements, 'string') ? def.anchorElements : [];
        out.id = GLOBAL_UTILS.typeCheck(def.id, 'string') ? def.id : this.createRandomId();
        out.orientation = this.cleanOrientation(def.orientation);
        out.positionEnd = this.cleanPosition(def.positionEnd);
        out.positionStart = this.cleanPosition(def.positionStart);
        out.snapRadius = GLOBAL_UTILS.typeCheck(def.snapRadius, 'notnegative') ? def.snapRadius : 1;
        return out;
    };
    SnapUtils.prototype.cleanAnchorInput = function (def) {
        var out = {};
        if (!def)
            return out;
        out.id = GLOBAL_UTILS.typeCheck(def.id, 'string') ? def.id : this.createRandomId();
        out.position = this.cleanPosition(def.position);
        out.orientation = this.cleanOrientation(def.orientation);
        return out;
    };
    SnapUtils.prototype.cleanPosition = function (position) {
        var out = new THREE.Vector3();
        if (GLOBAL_UTILS.typeCheck(position, 'vector3any'))
            out = GLOBAL_UTILS.toVector3(position);
        return out;
    };
    SnapUtils.prototype.cleanOrientation = function (orientation) {
        var out = {
            axis: new THREE.Vector3(0, 0, 1),
            direction: new THREE.Vector3(0, 1, 0),
        };
        if (orientation) {
            if (GLOBAL_UTILS.typeCheck(orientation.axis, 'vector3any'))
                out.axis = GLOBAL_UTILS.toVector3(orientation.axis);
            if (GLOBAL_UTILS.typeCheck(orientation.direction, 'vector3any'))
                out.direction = GLOBAL_UTILS.toVector3(orientation.direction);
        }
        return out;
    };
    return SnapUtils;
}());
exports.SnapUtils = SnapUtils;
