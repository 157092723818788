"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var BaseCustomComponent_1 = require("../../custom-components/BaseCustomComponent");
var LightDefinitionSettingComponent = /** @class */ (function (_super) {
    __extends(LightDefinitionSettingComponent, _super);
    // #endregion Properties (4)
    // #region Constructors (1)
    function LightDefinitionSettingComponent(_api, parameterDefinition, parent, domManager, queue, _controlsFactory, _dragControls) {
        var _this = _super.call(this, "group" /* GROUP */, parameterDefinition.id, parameterDefinition, parent, domManager, queue, false) || this;
        _this._api = _api;
        _this._controlsFactory = _controlsFactory;
        _this._dragControls = _dragControls;
        // #region Properties (4)
        _this._accordionType = "custom-parameter";
        _this._isLightUpdateRegistered = false;
        _this._removeId = parameterDefinition.id + "-multiple-remove";
        _this._rowId = _this.id + "-controlmenu-row";
        _this.createElement();
        _this.component.label.innerHTML = "<span id=\"" + _this._removeId + "\" uk-icon=\"icon: close; ratio: 0.8;\"></span>" + _this._parameterDefinition.name;
        _this.createInputEvents();
        return _this;
    }
    Object.defineProperty(LightDefinitionSettingComponent.prototype, "component", {
        // #endregion Constructors (1)
        // #region Public Accessors (7)
        get: function () {
            return this;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightDefinitionSettingComponent.prototype, "groupId", {
        get: function () {
            return this.parameterDefinition.groupId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightDefinitionSettingComponent.prototype, "hidden", {
        get: function () {
            return false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightDefinitionSettingComponent.prototype, "id", {
        get: function () {
            return this._parameterDefinition.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightDefinitionSettingComponent.prototype, "parameterDefinition", {
        get: function () {
            return this._parameterDefinition;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightDefinitionSettingComponent.prototype, "size", {
        get: function () {
            return 4 /* FULL_WIDTH */;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LightDefinitionSettingComponent.prototype, "type", {
        get: function () {
            return "light_definition" /* LIGHT_DEFINITION */;
        },
        enumerable: true,
        configurable: true
    });
    // #endregion Public Accessors (7)
    // #region Public Methods (1)
    LightDefinitionSettingComponent.prototype.open = function () {
        if (!this._parametersEl.classList.contains("uk-open")) {
            UIkit.accordion(this._parametersEl.parentNode).toggle(1);
        }
    };
    LightDefinitionSettingComponent.prototype.close = function () {
        if (this._parametersEl.classList.contains("uk-open")) {
            UIkit.accordion(this._parametersEl.parentNode).toggle(0);
        }
    };
    // #endregion Public Methods (1)
    // #region Protected Methods (5)
    LightDefinitionSettingComponent.prototype.createInput = function () {
        var _this = this;
        this._inputComponent = this._domManager.createDomElement("div", null, this._parametersEl, ["uk-accordion-content", "uk-margin-remove"], { "uk-grid": "" });
        this.parameterDefinition.parameters.forEach(function (x) {
            var customComponent = _this._controlsFactory.createShapeDiverSetting(x, _this._inputComponent);
            _this.children.push(customComponent);
            if (customComponent.label) {
                customComponent.label.classList.remove("uk-text-uppercase");
                customComponent.label.classList.add("uk-text-capitalize");
            }
        });
    };
    LightDefinitionSettingComponent.prototype.createInputEvents = function () {
        var _this = this;
        this._domManager.setDomEventListener(this._ulElem, "click", null, this.onClick.bind(this));
        this._domManager.setDomEventListener(this._ulElem, "click", 10, this.update.bind(this));
        this._domManager.setDomEventListener(document.getElementById(this._removeId), "click", null, function () { return _this.remove(); });
    };
    LightDefinitionSettingComponent.prototype.createLabel = function () {
        this._label = this._domManager.createDomElement("a", this.id + "-a", this._parametersEl, [
            "uk-accordion-title",
            "uk-width-expand",
            "uk-form-label",
            "uk-text-uppercase",
            "uk-text-small",
            "uk-padding-small",
            "sdv-control-panel-row-border"
        ]);
        this._label.innerHTML = this._parameterDefinition.name;
        if (this._parameterDefinition.title) {
            this._label.setAttribute("title", this._parameterDefinition.title);
        }
    };
    LightDefinitionSettingComponent.prototype.createRow = function () {
        this._row = this._domManager.createDomElement("row", this._rowId, this._parent, ["uk-margin-remove", "uk-padding-remove", "sd-max-width-1"], { "uk-grid": 'margin: "";' });
        this._ulElem = this._domManager.createDomElement("ul", null, this._row, ["uk-width-1-1"], { "uk-accordion": "multiple: false" });
        this._parametersEl = this._domManager.createDomElement("li", null, this._ulElem, ["jq-accordion-control-li"], { "data-setting-type": this._accordionType });
    };
    LightDefinitionSettingComponent.prototype.update = function (v, event) { };
    // #endregion Protected Methods (5)
    // #region Private Methods (5)
    LightDefinitionSettingComponent.prototype.closeOthers = function () {
        var _this = this;
        Array.from(document.getElementsByClassName("jq-accordion-control-li")).forEach(function (elem) {
            var type = elem.getAttribute("data-setting-type");
            if (type == _this._accordionType && !_this._parametersEl.isSameNode(elem)) {
                if (elem.classList.contains("uk-open")) {
                    UIkit.accordion(elem.parentNode).toggle(0);
                }
            }
        });
    };
    LightDefinitionSettingComponent.prototype.findChangedProperties = function (lightDef) {
        var _this = this;
        var result = [];
        Object.keys(lightDef.properties).forEach(function (key) {
            var prop = lightDef.properties[key];
            // check components for property if object
            if (typeof prop === "object") {
                var propKeys = Object.keys(prop);
                for (var i = 0; i < propKeys.length; i++) {
                    var propKey = propKeys[i];
                    if (_this.parameterDefinition.lightDef.properties[key] && prop[propKey] != _this.parameterDefinition.lightDef.properties[key][propKey]) {
                        result.push(key);
                        break;
                    }
                }
            }
            // just compare
            else {
                if (prop != _this.parameterDefinition.lightDef.properties[key]) {
                    result.push(key);
                }
            }
        });
        return result;
    };
    LightDefinitionSettingComponent.prototype.onClick = function () {
        this.closeOthers();
        this._dragControls.makeLightDraggable(this.parameterDefinition.lightDef.id);
        this.registerLightUpdate();
        this.updateInputElements(this._api.scene.lights.getLight(this.parameterDefinition.lightDef.id).data);
    };
    LightDefinitionSettingComponent.prototype.registerLightUpdate = function () {
        var _this = this;
        if (!this._isLightUpdateRegistered) {
            this._dragControls.onLightUpdate(this.parameterDefinition.lightDef.id, function (lightDef) {
                _this.updateInputElements(lightDef);
                return lightDef;
            });
        }
        this._isLightUpdateRegistered = true;
    };
    LightDefinitionSettingComponent.prototype.updateInputElements = function (lightDef) {
        var _this = this;
        if (!lightDef)
            return;
        this.findChangedProperties(lightDef).forEach(function (key) {
            _this.parameterDefinition.lightDef.properties[key] = lightDef.properties[key];
            var component = _this.children.find(function (x) { return x.id === key; });
            component.value = lightDef.properties[key];
        });
    };
    LightDefinitionSettingComponent.prototype.remove = function () {
        this.destroy();
        this._api.scene.lights.removeLight(this.parameterDefinition.lightDef.id);
    };
    return LightDefinitionSettingComponent;
}(BaseCustomComponent_1.BaseCustomComponent));
exports.LightDefinitionSettingComponent = LightDefinitionSettingComponent;
